import dynamic from "next/dynamic";

import CmsSocial from "./CmsSocial";

import CmsForm from "./CmsForm";
import CmsBookForm from "./CmsBookForm";
import Logo from "./Logo";
// import CmsSwiperIntro from "./CmsSwiperIntro";

const dynamicComponents = {
  // slideshow: dynamic(() => import("./CmsSlideshow")),
  text: dynamic(() => import("./CmsText")),
  image: dynamic(() => import("./CmsImage")),
  card: dynamic(() => import("./CmsCard")),
  cardGroup: dynamic(() => import("./CmsCardGroup")),
  swiper: dynamic(() => import("./CmsSwiper")),
  swiperIntro: dynamic(() => import("./CmsSwiperIntro")),
  video: dynamic(() => import("./CmsVideo")),
  attachment: dynamic(() => import("./CmsAttachment")),
  link: dynamic(() => import("./CmsButton")),
  collection: dynamic(() => import("./CmsCollection")),
  item: dynamic(() => import("./CmsCollectionItem")),
};

function CmsRow({ row, page_id = "" }) {
  if (row.id == "footer-2") {
    return (
      <section id={`${row.id ?? ""}`}>
        <div
          className={row.full ? "container-xxl mx-auto" : `container mx-auto`}
        >
          <div className={`griglia grid-cols-2 gap-8`}>
            <div className="footer-cols">
              <div className="logo">
                <Logo />
              </div>
              {row.components.map(function (component, i) {
                const Component = dynamicComponents[component.name];
                return (
                  <div
                    className={`col-span-${component.options.columns}`}
                    key={i}
                  >
                    <Component component={component} page_id={page_id} />
                  </div>
                );
              })}
            </div>
            <div>
              <CmsForm component={``} page_id={page_id} />
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section id={`${row.id ?? ""}`}>
        <div
          className={row.full ? "container-xxl mx-auto" : `container mx-auto`}
        >
          <div
            className={
              row.columns != "full" && row.columns > 1
                ? `griglia grid-cols-${row.columns} gap-8`
                : ""
            }
          >
            {row.components.map(function (component, i) {
              component.name =
                component.name == "card-group" ? "cardGroup" : component.name;
              component.name =
                component.id == "introslide" ? "swiperIntro" : component.name;
              component.name =
                component.name == "collection" &&
                typeof component.options.items[0].gallery !== "undefined"
                  ? "item"
                  : component.name;

              const Component = dynamicComponents[component.name];

              component.options.columns =
                typeof component.options.columns != "undefined"
                  ? component.options.columns
                  : 1;

              if (typeof Component != "undefined") {
                if (component.id == "contact-form") {
                  return (
                    <CmsForm component={component} page_id={page_id} key={i} />
                  );
                } else if (component.id == "book-form") {
                  return (
                    <CmsBookForm
                      component={component}
                      page_id={page_id}
                      key={i}
                    />
                  );
                } else {
                  return (
                    <div
                      className={`col-span-${component.options.columns}`}
                      key={i}
                    >
                      <Component component={component} page_id={page_id} />
                    </div>
                  );
                }
              } else {
                return (
                  <div key={i}>
                    {component.id == "footer-social" ? (
                      <>
                        <h5>Social</h5>
                        <CmsSocial />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default CmsRow;
