import CmsRow from "./CmsRow";

function CmsFooter({ structure }) {
  return (
    <footer id="footer">
      <div id="contact-us" />
      {structure.footer.map(function (row, i) {
        return (
          <CmsRow row={row} key={i} />
        )
      })}
    </footer>
  );
}

export default CmsFooter;
