import Link from "next/link";
import CmsMenuDropdown from "./CmsMenuDropdown";

export { NavLink };

function NavLink({ link, mobile=false}) {

  const hideMenu = (e) => {
    // setShow(false);
    document.getElementById("menu_mobile_overlay").classList.remove("active");
    document.getElementById("menu_mobile").classList.remove("active");
    document.body.classList.remove("slide-left");
  };

  if (typeof link != "undefined") {
    if (typeof link.navigation == "undefined" || link.navigation.length == 0) {
      // console.log(link.path);
      return (
        <Link href={link.path != "" ? link.path : "#"}>
          <a
            className={`${link.active ? "active" : ""}`}
            onClick={hideMenu}
          >
            {link.name}
          </a>
        </Link>
      );
    } else {
      return <CmsMenuDropdown link={link} mobile={mobile} />
    }
  } else {
    return <></>;
  }
}
