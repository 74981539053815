import React, { useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useRouter } from "next/router";

import { useTranslation } from "next-i18next";

function CmsForm() {
  const [showModal, setShowModal] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [showResult, setShowResult] = React.useState(0);
  const [customer, setCustomer] = React.useState(1);
  const { t } = useTranslation("common");

  const { asPath, locale, locales} = useRouter()

  useEffect(() => {
    setShowModal(true);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      name: event.target.name.value,
      surname: event.target.surname.value,
      company: customer == 1 ? event.target.company.value : "",
      role: customer == 1 ? event.target.role.value : "",
      email: event.target.email.value,
      subject: "Messaggio dal sito DE LORENZI",
      message: event.target.message.value,
    };

    setShowLoading(true);

    // const response = await sendMail(data);

    fetch(process.env.BASE_URL + "/api/sendmail", {
      body: JSON.stringify(data),
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        setShowLoading(false);
        if (data.response == 1) {
          setShowResult(1);
        } else {
          setShowResult(2);
        }
      })
      .catch((error) => {
        setShowLoading(false);
        setShowResult(2);
      });
  };

  const closeForm = () => {
    document.querySelector("body, html").classList.remove("no-scroll");
    setShowModal(false);
    setShowResult(0);
  };

  const changeCustomer = (e) => {
    setCustomer(e.target.value);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="contact-form">
            <div className="contact-form-container relative">
              {showResult == 0 ? (
                <>
                  {showLoading ? (
                    <div className="absolute w-full h-full theme-b5 z-10 flex flex-col justify-center items-center">
                      <TailSpin
                        type="Puff"
                        color="#FFF"
                        height={70}
                        width={70}
                        timeout={3000}
                      />
                      <div>{t("sending")}...</div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/*content*/}
                  <form className="w-full" onSubmit={handleSubmit}>
                    <div className="form-contact">
                      {/*body*/}
                      <div className="relative flex-auto">
                        <div className="form-row md:grid grid-cols-2 gap-2">
                          <div className="form-fld">
                            <div className="flex items-center">
                              <input
                                className="mr-2"
                                id="company_radio"
                                type="radio"
                                checked={customer == 1}
                                onChange={changeCustomer}
                                value="1"
                              />
                              <label htmlFor="company_radio">
                                {t("i_am_a_company")}
                              </label>
                            </div>
                          </div>
                          <div className="form-fld">
                            <div className="flex items-center">
                              <input
                                className="mr-2"
                                id="private_radio"
                                type="radio"
                                checked={customer == 2}
                                onChange={changeCustomer}
                                value="2"
                              />
                              <label htmlFor="private_radio">
                                {t("i_am_a_private_person")}
                              </label>
                            </div>
                          </div>
                          <div className="form-fld">
                            <input
                              id="form_name"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="text"
                              placeholder={`${t("name")} (${t("required")})`}
                              aria-label={`${t("name")} (${t("required")})`}
                              name="name"
                              required
                            />
                          </div>
                          <div className="form-fld">
                            <input
                              id="form_name"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="text"
                              placeholder={`${t("surname")} (${t("required")})`}
                              aria-label={`${t("surname")} (${t("required")})`}
                              name="surname"
                              required
                            />
                          </div>
                          {customer == 1 && (
                            <div className="form-fld">
                              <input
                                id="form_company"
                                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                                type="text"
                                placeholder={`${t("company")} (${t(
                                  "required"
                                )})`}
                                aria-label={`${t("company")} (${t(
                                  "required"
                                )})`}
                                name="company"
                                required
                              />
                            </div>
                          )}
                          {customer == 1 && (
                            <div className="form-fld">
                              <input
                                id="form_role"
                                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                                type="text"
                                placeholder={`${t("role")}`}
                                aria-label={`${t("role")}`}
                                name="role"
                              />
                            </div>
                          )}
                          <div className="form-fld col-span-2">
                            <input
                              id="form_email"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="text"
                              placeholder={`Email (${t("required")})`}
                              aria-label={`Email (${t("required")})`}
                              name="email"
                              required
                            />
                          </div>
                          <div className="form-fld col-span-2">
                            <textarea
                              id="form_message"
                              className="appearance-none bg-transparent border-none w-full h-32 text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="text"
                              placeholder={`${t("message")} (${t("required")})`}
                              aria-label={`${t("message")} (${t("required")})`}
                              name="message"
                              required
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      {/*footer*/}
                      <div className="text-center">
                        {t("privacy_note")}
                        {` `}
                        {locale == "it" ? (
                          <a
                            href="https://www.iubenda.com/privacy-policy/36022623/full-legal"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy
                          </a>
                        ) : (
                          <a
                            href="https://www.iubenda.com/privacy-policy/15082607/full-legal"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy
                          </a>
                        )}
                      </div>
                      <div className="flex items-center justify-center p-6">
                        <button className="externalBtn" type="submit">
                          {t("send")}
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <div className="form-cms-container-inner">
                    <div className="relative p-6 flex-auto">
                      <div className="z-10 flex flex-col justify-center items-center">
                        {showResult == 1 ? (
                          <>
                            <h5 className="theme-c1 font-bold text-center">
                              {t("thanks")}
                            </h5>
                            <p className="font-normal text-center">
                              {t("thanks_txt")}
                            </p>
                            {/* <div className="mt-8 mb-12">
                              <button
                                className="sendBtn"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  closeForm();
                                }}
                              >
                                Chiudi
                              </button>
                            </div> */}
                          </>
                        ) : (
                          <>
                            <h5 className="text-red-600 text-center">
                              {t("error")}
                            </h5>
                            <p className="font-normal mt-8 text-center">
                              {t("error_txt")}{" "}
                              <a
                                href={`mailto:${process.env.MAIL_TO}`}
                                className="theme-c2"
                              >
                                {process.env.MAIL_TO}
                              </a>
                            </p>
                            {/* <div className="mt-8 mb-12">
                              <button
                                className="sendBtn"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  closeForm();
                                }}
                              >
                                Chiudi
                              </button>
                            </div> */}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default CmsForm;
