import React from "react";
import Image from "next/image";

export default function Logo({ size }) {
  return (
    <>
    <Image
        alt=""
        src="/images/logo.png"
        layout="fixed"
        width="270"
        height="105"
        priority={true}
      />
    </>
  )
}