import React, { useState, useEffect } from "react";
import Link from "next/link";

import Logo from "./Logo";
import MiniLogo from "./MiniLogo";
import { NavLink } from "./NavLink";
import LangMenu from "./LangMenu";

import { useTranslation } from 'next-i18next';

function NavBarMobile({ navigation }) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation('common');

  useEffect(() => {}, []);

  const showMenu = (e) => {
    // setShow(true);
    document.getElementById("menu_mobile_overlay").classList.add("active");
    document.getElementById("menu_mobile").classList.add("active");
    document.body.classList.add("slide-left");
  };

  const hideMenu = (e) => {
    // setShow(false);
    document.getElementById("menu_mobile_overlay").classList.remove("active");
    document.getElementById("menu_mobile").classList.remove("active");
    document.body.classList.remove("slide-left");
  };

  if (typeof window !== "undefined") {
    window.addEventListener("resize", hideMenu);
  }

  return (
    <nav className="mainNavMobile">
      <div className="container mx-auto h-full">
        <div className="flex h-full w-full justify-between items-center">
          <Link href="/">
            <a className="Logo">
              <Logo />
            </a>
          </Link>
          <Link href="/">
            <a className="MiniLogo">
              <MiniLogo />
            </a>
          </Link>
          <div id="burger" onClick={showMenu}>
            <span></span>
          </div>
          <div
            id="menu_mobile_overlay"
            // className={show ? "active" : ""}
            onClick={hideMenu}
          ></div>
          <div id="menu_mobile" 
          // className={show ? "active" : ""}
          >
            <div className="navBarMobile_head">
                <span className="closeBtn" onClick={hideMenu}></span>
            </div>
            <ul className={`navBarMobile`}>
              {navigation.map(function (link, i) {
                return (
                  <li key={i}>
                    <NavLink link={link} exact="true"></NavLink>
                  </li>
                );
              })}
              <li>
                <Link href="#contact-us">
                  <a onClick={hideMenu}>{t("contacts")}</a>
                </Link>
              </li>
              <li id="languages">
                <LangMenu />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBarMobile;
