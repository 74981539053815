import React, { useState, useEffect } from "react";
import Head from "next/head";
import CmsRow from "./CmsRow";
import CmsFooter from "./CmsFooter";
import { useRouter } from "next/router";

import Aos from "aos";
import "aos/dist/aos.css";

function CmsPage({ structure }) {

  const { asPath, locale, locales} = useRouter()

  useEffect(() => {
  }, []);

  var iubendaImplementation = '';

  if(locale == 'it') {
    iubendaImplementation = `
    <script type="text/javascript">
    var _iub = _iub || [];
    _iub.csConfiguration = {"lang":"it","siteId":1820882,"cookiePolicyInOtherWindow":true,"consentOnScroll":false,"consentOnLinkAndButton":false,"consentOnElement":null,"perPurposeConsent":true,"ccpaAcknowledgeOnDisplay":true,"enableCcpa":true,"countryDetection":true,"ccpaNoticeDisplay":true,"whitelabel":false,"enableTcf":true,"cookiePolicyId":36022623, "banner":{ "position":"float-top-center","textColor":"black","backgroundColor":"white","rejectButtonDisplay":true,"acceptButtonDisplay":true,"acceptButtonColor":"#0073CE","acceptButtonCaptionColor":"white","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","slideDown":false,"prependOnBody":false,"customizeButtonDisplay":true,"customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D" }};
    </script>
    <script type="text/javascript" src="//cdn.iubenda.com/cs/tcf/stub-v2.js"></script>
    <script type="text/javascript" src="//cdn.iubenda.com/cs/tcf/safe-tcf-v2.js"></script>
    <script type="text/javascript" src="//cdn.iubenda.com/cs/ccpa/stub.js"></script>
    <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>`;
  } else {
    iubendaImplementation = `
    <script type="text/javascript">
    var _iub = _iub || [];
    _iub.csConfiguration = {"lang":"en","siteId":1820882,"cookiePolicyInOtherWindow":true,"consentOnScroll":false,"consentOnLinkAndButton":false,"consentOnElement":null,"perPurposeConsent":true,"ccpaAcknowledgeOnDisplay":true,"enableCcpa":true,"countryDetection":true,"ccpaNoticeDisplay":true,"whitelabel":false,"enableTcf":true,"cookiePolicyId":15082607, "banner":{ "position":"float-top-center","textColor":"black","backgroundColor":"white","rejectButtonDisplay":true,"acceptButtonDisplay":true,"acceptButtonColor":"#0073CE","acceptButtonCaptionColor":"white","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","slideDown":false,"prependOnBody":false,"customizeButtonDisplay":true,"customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D" }};
    </script>
    <script type="text/javascript" src="//cdn.iubenda.com/cs/tcf/stub-v2.js"></script>
    <script type="text/javascript" src="//cdn.iubenda.com/cs/tcf/safe-tcf-v2.js"></script>
    <script type="text/javascript" src="//cdn.iubenda.com/cs/ccpa/stub.js"></script>
    <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>`;
  }

  return (
    <>
      <Head>
        <title>{structure.page.title}</title>
        <meta name="description" content={structure.page.description} />
      </Head>
      <div dangerouslySetInnerHTML={{ __html: iubendaImplementation }}></div>
      <main
        className={`${structure.page.template} cmsPage`}
        id={`${structure.page.slug ?? ""}`}
      >
        {structure.rows.map(function (row, i) {
            return <CmsRow row={row} page_id={structure.page.id} key={i} />;
        })}
      </main>
      <CmsFooter structure={structure} />
    </>
  );
}

function applyAosToElement(selector, effect) {
  if (document.querySelector(selector)) {
    document.querySelector(selector).setAttribute("data-aos", effect);
  }
}

function applyAosToElementByIndex(selector, index, effect) {
  if (document.querySelectorAll(selector)[index]) {
    document.querySelectorAll(selector)[index].setAttribute("data-aos", effect);
  }
}

export default CmsPage;
